import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    mode: "light",

    primary: {
      main: "#FF8A00",
    },

    secondary: {
      main: "#3C4350",
    },
    warning: {
      main: "#ed6c02",
    },
    // success: {

    // },
    text: {
      primary: "#2c405a",
      secondary: "#657786",
    },
    actions: {},
    background: {
      default: "#F2F2F2",
      paper: "#ffffff",
    },
  },

  // Override custom
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '37px',
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
        shrink: {
          // color: "#2c405a !important", // Change this to the color you want
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "150px",
          height: "48px !important",
          fontSize: "1rem",
          textTransform: "none",
          fontWeight: 400,
          boxShadow: "none",
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
        cell: {
          "&:focus": {
            outline: "none!important",
            background: "white!important",
          },

          "&.Mui-selected": {
            // Targeting the selected row
            outline: "none!important",
            background: "white!important",
          },
          "&.Mui-selected:hover": {
            // Targeting the selected row
            outline: "none!important",
            background: "white!important",
          },
          "&:hover": {
            outline: "none!important",
            background: "white!important",
          },
        },
        row: {
          "&:focus": {
            outline: "none!important",
            background: "white!important",
          },
          "&.Mui-hovered": {
            backgroundColor: "transparent !important",
          },
          "&.Mui-selected": {
            // Targeting the selected row
            outline: "none!important",
            background: "white!important",
          },
          "&.Mui-selected.Mui-hovered": {
            // Targeting the selected row
            outline: "none!important",
            background: "white!important",
          },
          "&.Mui-selected.Mui-hover": {
            // Targeting the selected row
            outline: "none!important",
            background: "white!important",
          },
          "&:hover": {
            outline: "none!important",
            background: "white!important",
          },
        },
      },
    },
    AppBar: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          height: "28px",
          display: "flex",
          alignItems: "center",
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      },
    },
  },
});

export default theme;
