import React from "react";
// import { DataGrid } from "@mui/x-data-grid";
import { ListWrapper, StyledDataGrid } from "../styles";

const ListTable = ({ data, arrayColumns, printRef }) => {
  const [pageSize, setPageSize] = React.useState(5);

  return (
    <ListWrapper ref={printRef} className={'content-container'}>
      <StyledDataGrid
        sx={{
          "& .MuiDataGrid-row.Mui-hovered": {
            backgroundColor: "transparent !important",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
            borderRight: "none",
          },

          "&.MuiDataGrid-cell:focus": {
            outline: "none",
            background: "white",
          },
          "&.MuiDataGrid-cell:selected": {
            outline: "none",
            background: "white",
          },
          '& .MuiDataGrid-footerContainer': {
            minHeight: 0, // Adjust or remove minimum height
            '.MuiDataGrid-footer': {
                display: 'none', // Hide footer
            }
          }
        }}
        isRowSelectable={false}
        isCellEditable={false}
        disableRowSelectionOnClick={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        autoHeight={true}
        rowHeight={72}
        getRowId={(data) => data.siteId}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        isEditable={false}
        columns={arrayColumns}
        pageSize={15}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 15, 100]}
        rows={data}
        disableColumnMenu={true}
        disableColumnFilter={true}
      />
    </ListWrapper>
  );
};

export default ListTable;
