import { styled, Typography, Button, Box, IconButton } from "@mui/material";

export const ScanDialogText = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 500,
  color: '#1C1B1C',
  textAlign: 'center',
  marginLeft: theme.spacing(1),
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  }
}));
export const ScanDialogIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  color: '#929398',
  [theme.breakpoints.down('sm')]: {
    top: '8px',
  }
}));

export const ScanInnerText = styled(Typography)(() => ({
  color: '#475156',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  textAlign: 'center'
}));

export const ScanButton = styled(Button)(({ theme }) => ({
  color: '#626A7A',
  marginTop: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(0, 2),
  border: 'none',
  position: 'relative',
  '&:hover': {
    color: '#626A7A',
    border: 'none',
    background: 'white'
  }
}));

export const ScanInnerButtonText = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '500px',
  fontWeight: 500,
  fontSize: '0.875rem',
}));

export const ScanInnerCopiedText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '125px',
  right: '76px',
  marginTop: theme.spacing(1),
  background: '#626A7A',
  padding: '4px 12px',
  borderRadius: '6px',
}));