import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { CustomButton } from "./styles";

const LoadingButton = ({ isLoading, children, ...rest }) => {
  return (
    <CustomButton disabled={isLoading} {...rest}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {children}
        {isLoading && (
          <CircularProgress sx={{ ml: 2 }} size={24} color="inherit" />
        )}
      </Box>
    </CustomButton>
  );
};
export default LoadingButton;
