import PoweredByOrdersCo from 'assets/images/poweredbyorders.png';
import {PrivacyText, StyledFooterBox, StyledGridFooterContainer, StyledGridPolicyContainer} from "pages/Result/styles";


const Footer  = () => {
  return (
   <StyledFooterBox>
     <StyledGridFooterContainer>
       <StyledGridPolicyContainer>
         <PrivacyText
           target="_blank"
           href={`https://orders.co/terms-of-use/`}
         >Terms and conditions</PrivacyText>
         <PrivacyText
           target="_blank"
           href={`https://orders.co/privacy-policy/`}
         >Privacy Policy</PrivacyText>
       </StyledGridPolicyContainer>
       <img src={PoweredByOrdersCo} alt="poweredbyorders" />
     </StyledGridFooterContainer>
   </StyledFooterBox>
  )
}
export default Footer;