import { Box, Button, Container, Typography } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="lg" sx={{ display: "flex" }}>
          <Typography variant="h2" align="center">
            Oops, there is an error!
          </Typography>
          <Button
            id="ErrorBoundaryTryAgain?"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </Button>
        </Container>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
