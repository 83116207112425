import {Box, Typography, styled, Button, Grid, Link} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  boxShadow:
    "0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
  background: '#1C1B1C',
  display: "flex",
  paddingLeft: '66px',
  paddingRight: '66px',
  minHeight: '95px',
  '@media print': {
    boxShadow:
        "0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    background: '#1C1B1C',
    display: "flex",
    paddingLeft: '66px',
    paddingRight: '66px',
    minHeight: '95px',
  },
  [theme.breakpoints.down(778)]: {
    paddingLeft: '16px',
    paddingRight: '16px'
  }
}));

export const StyledFooterBox = styled(Box)(({ theme }) => ({
  boxShadow:
    "0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
  background: '#1C1B1C',
  display: "flex",
  paddingLeft: '66px',
  paddingRight: '66px',
  minHeight: '74px',
  position: 'absolute',
  width: '100%',
  '@media print': {
    boxShadow:
      "0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    background: '#1C1B1C',
    display: "flex",
    paddingLeft: '66px',
    paddingRight: '66px',
    minHeight: '95px',
  },
  [theme.breakpoints.down(778)]: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    position: 'inherit',
  }

}));

export const StyledHeaderDescription = styled(Typography)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.87)",
  fontSize: "18px",
  lineHeight: '27px',
  '@media print': {
    color: "rgba(255, 255, 255, 0.87)",
    fontSize: "18px",
    lineHeight: '27px',
  },
}));

export const RescanButton = styled(Button)(({ theme }) => ({
  width: '123px',
  border: '1px solid #FFFFFF',
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: '27px',
  cursor: 'pointer',
  borderRadius: '4px',
  '@media print': {
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: '27px',
    borderRadius: '4px',
  },
  '&:hover': {
    border: '1px solid #FFFFFF',
    color: "#FFFFFF",
    borderRadius: '4px',
  },
  '&:disabled': {
    border: '1px solid #FFFFFF',
    color: "#FFFFFF",
    opacity: '0.5',
    cursor: 'not-allowed',
    borderRadius: '4px',
  },
  [theme.breakpoints.down(778)]: {
    margin: theme.spacing(2),
  }
}));

export const StyledHeaderLogo = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    marginLeft: '24px'
  }
}));
export const StyledGridContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "100%",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media print': {
    width: '100%',
    maxWidth: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down(778)]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

export const StyledGridFooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "100%",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media print': {
    width: '100%',
    maxWidth: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      marginBottom: theme.spacing(1.5),
    }
  }
}));

export const StyledGridPolicyContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "100%",
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  '@media print': {
    width: '100%',
    maxWidth: "100%",
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: "center",
    marginTop: theme.spacing(1.5),
  }
}));

export const PrivacyText = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  textDecoration: 'none',
  cursor: 'pointer',
  color: 'white',
  '@media print': {
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

export const ListTableHead = styled(Box)(({ theme }) => ({
  "& .title": {
    fontSize: "24px",
    fontWeight: 500,
    color: "#3C4350",
  },
}));

export const ListTableWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "1200px",
  "& .title": {
    fontSize: "24px",
    fontWeight: 500,
    color: "#3C4350",
  },
}));

export const StyledBusinessInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(3),
  flexDirection: "column",
  "& p": {
    fontSize: "14px",
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

export const ResultBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "88px",
  boxSizing: "border-box",
  marginTop: theme.spacing(3),
  borderRadius: "6px",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  background: theme.palette.secondary.main,
  ".resultCount": {
    // color: theme.palette.primary.main,
    fontSize: "66px",
    fontWeight: 400,
    marginRight: "10px",
  },
  [theme.breakpoints.down('1201')]: {
    width: '100%'
  }
}));

export const ResultDesc = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "> p": {
    fontSize: "24px",
    color: "white",
  },
  [theme.breakpoints.down(420)]: {
    "> p": {
      fontSize: "18px",
      color: "white",
    },
  }
}));

export const ListWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  ".MuiDataGrid-columnHeaders": {
    background: '#1C1B1C',
  },
  ".MuiDataGrid-columnHeader": {
    color: "white",
  },
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: '32px',
  '& .MuiDataGrid-main': {
    borderRadius: '32px',
  },
  '& .MuiDataGrid-root': {
    borderRadius: '32px',
  },
  '& .MuiDataGrid-footerContainer': {
    display: 'none'
  }
}));

export const ElipsedTypography = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block",
  whiteSpace: "nowrap",
  WebkitBoxOrient: "vertical",
  width: "100%",
}));
export const ElipsedTypographyAddress = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block",
  whiteSpace: "nowrap",
  width: "100%",
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}));

export const ResultPageTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '36px',
  color: '#1C1B1C',
  '& > span': {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '27px',
  }
}));


export const FormListWrapper = styled(Box)(({ theme }) => ({
  padding: '0 24px',
  [theme.breakpoints.down('md')]: {
    padding: '0 16px',
  }
}));

export const ListingTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: '27px',
  textAlign: "center"
}));

export const ResultSecondHeaderWrapper = styled(Box)(({ theme }) => ({
  background: '#EFEFEF',
  minHeight: '151px',
  textAlign: 'center',
  padding: '32px 16px',
  boxShadow:
    "0px 2px 6px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
  '@media print': {
    textAlign: 'center',
    background: '#EFEFEF',
    minHeight: '151px',
    boxShadow:
      "0px 2px 6px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
    padding: '32px 16px',
  },
}));

export const ResultSecondHeaderTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 27, 28, 1)',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '45px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: 'inherit',
  }
}));

export const ResultSecondHeaderButton = styled(Button)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 1)',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '27px',
  background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
  marginTop: theme.spacing(1),
  textTransform: 'uppercase',
  width: '255px',
  borderRadius: '4px',
  '&:hover': {
    color: 'rgba(255, 255, 255, 1)',
    background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
    textTransform: 'uppercase',
    boxShadow: 'none',
    borderRadius: '4px',
  },
  '@media print': {
    marginTop: theme.spacing(1),
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
    background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
    textTransform: 'uppercase',
    borderRadius: '4px',
  },
}));

export const BusinessName = styled(Typography)(({ theme }) => ({
  fontSize: '40px',
  fontWeight: 700,
  color: '#1C1B1C',
  lineHeight: '60px',
}));

export const ListingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  flexWrap: 'wrap',
}));

export const ShareButton = styled(Button)(({ theme }) => ({
  width: '221px',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.15)',
  color: 'rgba(28, 27, 28, 1)',
  boxShadow: 'none',
  '&:hover': {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'rgba(28, 27, 28, 1)',
    boxShadow: 'none',
    background: 'white',
    borderRadius: '4px',
  }
}));

export const BusinessMainInfoWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),
  minHeight: '110px',
  display: 'flex',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

export const ContactInfoWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '369px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  }
}));

export const DataErrorInfoWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '320px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  }
}));

export const ContactInfoTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19px',
}));

export const ContactInfoInnerWrapper = styled(Box)(({ theme }) => ({
  background: 'rgba(239, 239, 239, 1)',
  minHeight: '98px',
  borderRadius: '8px',
  marginTop: theme.spacing(0.5)
}));

export const DataErrorInfoInnerWrapper = styled(Box)(({ theme }) => ({
  background: 'white',
  border: '1px solid rgba(255, 107, 0, 1)',
  borderRadius: '8px',
  minHeight: '98px',
  marginTop: theme.spacing(0.5)
}));

export const ContactInfoBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2)
}));

export const ErrorCount = styled(Typography)(({ theme }) => ({
  color: 'rgba(255, 138, 0, 1)',
  fontSize: '45px',
  fontWeight: 700,
  lineHeight: '67.5px'
}));

export const ErrorCountTextBox = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const LocationDataTitle = styled(Typography)(({ theme }) => ({
  color: 'rgba(28, 27, 28, 1)',
  lineHeight: '24px',
  fontSize: '20px',
  fontWeight: 600,
}));

export const PerformanceWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  maxWidth: '633px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  }
}));

export const PerformanceInnerWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '633px',
  background: 'white',
  border: '1px solid rgba(255, 107, 0, 1)',
  borderRadius: '8px',
  minHeight: '102px',
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  }
}));

export const PerformanceOrderWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: 'flex',
  gap: '16px',
  alignItems: 'end',
  [theme.breakpoints.down('lg')]: {
    alignItems: 'center'
  }
}));

export const PerformanceOrderText = styled(Typography)(({ theme }) => ({
  fontSize: '45px',
  fontWeight: 600,
  lineHeight: '67.5px',
  padding: 0,
}));

export const PerformanceOrderTextSmall = styled(Typography)(({ theme }) => ({
  padding: 0,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14px',
}));

export const PerformanceOrderDesc = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '21px',
  color: '#1C1B1C',
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(1),
}));