import React from "react";
import { Route, Routes } from "react-router-dom";
import YexyCreate from "pages/YexyCreate/index";
import Result from "pages/Result/index";
import NotFound from "components/NotFound/Index";
import "./App.css";
import ErrorBoundary from "components/ErrorBoundary";

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Routes>
          <Route path={"/"} element={<YexyCreate />} />
          <Route path={"/scanResult"} element={<Result />} />
          <Route path={"/*"} element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
