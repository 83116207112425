import { Box, styled } from "@mui/material";

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  background: '#1C1B1C',
  minHeight: '95px',
  position: 'absolute',
  width: '100%',
  '& > img': {
    height: '33px',
    width: '135px',
    margin: '24px 64px'
  },
  [theme.breakpoints.down('sm')]: {
    '& > img': {
      margin: '24px'
    },
  }
}));

export const HeaderWrapperBox = styled(Box)(({ theme }) => ({
  margin: '24px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    margin: '24px auto'
  }
}));