import React, { useState } from "react";
import "../index.css";
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "components/LoadingButton";
import {
  ScanButton,
  ScanDialogIconButton,
  ScanDialogText,
  ScanInnerButtonText,
  ScanInnerCopiedText,
  ScanInnerText
} from "./styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Dialog, DialogContent, IconButton, DialogActions, Typography } from '@mui/material';
const ScanDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <ScanDialogText {...other}>
      {children}
      {onClose ? (
        <ScanDialogIconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon sx={{ color: '#929398' }} />
        </ScanDialogIconButton>
      ) : null}
    </ScanDialogText>
  );
};


const ScanDialog = ({ open, handleClose, url }) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const handleCopy = () => {
    setShowCopyMessage(true);
    navigator?.clipboard?.writeText(url);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperProps={{ sx: { width: '538px', borderRadius: '20px' } }}
    >
      <ScanDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Share This Report Link
      </ScanDialogTitle>
      <DialogContent sx={{ width: '100%', padding: 0, cursor: 'auto' }}>
        <ScanInnerText>Please copy and share this link</ScanInnerText>
        <ScanButton
          onClick={handleCopy}
          variant="outlined"
          endIcon={<ContentCopyIcon />}
        >
          <ScanInnerButtonText>
            {url}
          </ScanInnerButtonText>
        </ScanButton>
        <ScanInnerCopiedText
          className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}
        >
          <Typography color="white" variant="body2" className="message-text">
            Copied
          </Typography>
        </ScanInnerCopiedText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: 3 }}>
        <LoadingButton
          sx={{
            width: "171px",
            textAlign: 'center',
            color: "white",
            height: "40px",
            fontSize: '16px',
            fontWeight: 700,
            borderRadius: '6px',
            background: 'linear-gradient(to right, rgba(255, 107, 0, 1) 0%, rgba(173, 42, 0, 1) 100%)',
          }}
          variant="contained"
          type="submit"
          size="small"
          isLoading={false}
          onClick={handleClose}
        >
          DONE
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ScanDialog;