import { Box, Typography } from "@mui/material";
import notFound from "assets/images/NotFound.svg";

export default function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        px: 4,
        height: "100vh",
      }}
    >
      <img src={notFound} alt="Restaurant Not Found" width={316} />

      <Typography sx={{ fontSize: "24px", fontWeight: 700, my: 2 }}>
        Oops! Page no found
      </Typography>
      <Typography
        sx={{ width: "398px", textAlign: "center", mb: 4, color: "#929398" }}
      >
        We’re sorry, but the page you were trying to reach doesn’t exist on our
        site. It may have been moved, updated or deleted.
      </Typography>
    </Box>
  );
}
