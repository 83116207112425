import { useMediaQuery } from "@mui/material";

const useScreenSize = () => {
  const isSmallMobile =  useMediaQuery("(max-width:380px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(min-width:601px) and (max-width:1200px)");
  const isWeb = useMediaQuery("(min-width:1201px)");

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isWeb,
  };
};

export default useScreenSize;
