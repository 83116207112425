import {Box, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  PerformanceInnerWrapper, PerformanceOrderDesc,
  PerformanceOrderText,
  PerformanceOrderTextSmall,
  PerformanceOrderWrapper,
  ResultDesc
} from "pages/Result/styles";
import React, {useEffect, useState} from "react";
import {PERFORMANCE} from "constants/config";


const Performance = ({ errorCount, loading, loadingMore }) => {
  const [performanceConfig, setPerformanceConfig]= useState(null);

  useEffect(() => {
    if (!loading) {
      const data = PERFORMANCE.find(({ min_error_count, error_max_count }) =>
        errorCount >= min_error_count && errorCount <= error_max_count);
      setPerformanceConfig(data);
    }
  },[errorCount, loading])

  return (
    <PerformanceInnerWrapper sx={{ border: `1px solid ${performanceConfig?.color || 'rgba(255, 107, 0, 1)'}` }}>
      <PerformanceOrderWrapper>
        {loading ? (
          <Box sx={{ margin: '20px auto' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              <PerformanceOrderText sx={{ color: performanceConfig?.color }}>
                {performanceConfig?.order}
              </PerformanceOrderText>
              <PerformanceOrderTextSmall sx={{ color: performanceConfig?.color }}>
                {performanceConfig?.title}
              </PerformanceOrderTextSmall>
            </Box>
            <PerformanceOrderDesc>{performanceConfig?.text}</PerformanceOrderDesc>
          </>
        )}
      </PerformanceOrderWrapper>
    </PerformanceInnerWrapper>
  )
}

export default Performance