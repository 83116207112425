import React from 'react';

const LabelRequired = ({ children }) => {
  return (
    <>
      {children}
      <span className="ml-1">*</span>
    </>
  );
};

export default LabelRequired;
