import React, { createContext, useContext, useState } from "react";
export const FormContext = createContext();

const initialData = {
  address: "",
  city: "",
  countryCode: "",
  name: "",
  phone: "",
  state: "",
  zip: "",
};

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialData);

  const updateFormData = (data) => {
    setFormData(data);
  };
  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
