export const GOOGLE_API_KEY = "AIzaSyDYfjHl41cQl4BQZZ35YKPh-sHhL0Vv2ZM";
export const STATUSES = {
  SCAN_TEMPORARY_PROBLEM: {
    label: "Alert",
    name: "warning",
    value: "SCAN_TEMPORARY_PROBLEM",
  },
  SCAN_LISTED: { label: "Listed", name: "success", vlaue: "SCAN_LISTED" },
  SCAN_ALERT: { label: "Alert", name: "warning", value: "SCAN_ALERT" },
  SCAN_NOT_FOUND: {
    label: "Not found",
    name: "error",
    value: "SCAN_NOT_FOUND",
  },
  SCAN_IN_PROGRESS: {
    label: "Not found",
    name: "error",
    value: "SCAN_IN_PROGRESS",
  },
};


export const PERFORMANCE = [
 {
   order: 'A',
   title: 'Excellent',
   min_error_count: 0,
   error_max_count: 29,
   color: '#1DD3A8',
   borderColor: '#1DD3A8',
   percent: '98%',
   text: 'Your restaurant\'s local listings outshine competitors, with minimal errors and consistent information across platforms. Partnering with Orders.co ensures ongoing management and optimization to maintain your top position.'
 },
 {
    order: 'B',
    title: 'Good',
    min_error_count: 30,
    error_max_count: 49,
    color: '#5581B6',
    borderColor: '#5581B6',
    percent: '82%',
    text: 'Your restaurant\'s local listings are competitive, but there\'s room for improvement to surpass industry standards. With Orders.co, you\'ll receive expert management to stay ahead of the competition.',
  },
  {
    order: 'C',
    title: 'Fair',
    min_error_count: 50,
    error_max_count: 89,
    color: '#FF8A00',
    borderColor: '#FF8A00',
    percent: '75%',
    text: 'Your restaurant\'s local listings lag behind some competitors, highlighting areas for enhancement. Joining forces with Orders.co guarantees comprehensive management and updates to boost your visibility and outperform rivals.',
  },
  {
    order: 'D',
    title: 'Poor',
    min_error_count: 90,
    error_max_count: 10000,
    color: '#ED2024',
    borderColor: '#ED2024',
    percent: '63%',
    text: 'Your restaurant\'s local listings fall behind competitors, indicating significant gaps in online presence. Let Orders.co take the reins to swiftly address errors and elevate your listings to surpass rivals.'
  },
];

export const FETCH_URL = 'https://maps.googleapis.com/maps/api/place/details/json';
export const FIELDS = 'name,type,types,business_status,vicinity,current_opening_hours,formatted_phone_number,rating,reviews,curbside_pickup,delivery,dine_in';