import React, {useEffect, useState, useRef} from "react";
import ShareIcon from 'assets/images/share.png';
import {
  Alert,
  Rating,
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Link as MuiLink,
  LinearProgress,
} from "@mui/material";
import {
  BusinessMainInfoWrapper,
  BusinessName,
  ContactInfoBox,
  ContactInfoInnerWrapper,
  ContactInfoTitle,
  ContactInfoWrapper,
  DataErrorInfoInnerWrapper,
  DataErrorInfoWrapper,
  ElipsedTypography,
  ElipsedTypographyAddress,
  ErrorCount,
  ErrorCountTextBox,
  ListingWrapper,
  LocationDataTitle,
  PerformanceWrapper,
  ResultPageTypography,
  ShareButton,
} from "./styles";
import ListTableWeb from "./components/ListTableWeb";
import {
  useCreateReportShortLinkMutation,
  useLazyGetYexyScanCreateQuery,
  useLazyGetReportForBusinessQuery
} from "api";
import useScreenSize from "hooks/useScreenSize";
import {STATUSES} from "constants/config";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ListTableMobile } from "./components/ListTableMobile";
import CircularProgress from "@mui/material/CircularProgress";
import {  useLocation } from "react-router-dom";
import { decryptText } from "helpers/crypto";
import Performance from "components/Performance/Performance";
import ResultPageHeader from "components/Header/ResultPageHeader";
import ResultPageInfoHeader from "components/Header/ResultPageInfoHeader";
import ScanDialog from "shred/ScanDialog";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Footer from "../../components/Footer/Footer";
const Result = () => {
  const location = useLocation();
  const printRef = useRef();
  // const { formData } = useContext(FormContext);
  const [data, setData] = useState([]);
  const [shortLink, setShortLink] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const params = searchParams.get("params");
  const reportId = searchParams.get("reportId");
  const decryptData = params ? JSON.parse(decryptText(params)) : null;
  const [businessInfo, setBusinessInfo] = useState({
    address: '',
    phone: ''
  });
  const [reportJobId, setReportJobId] = useState('');

  const [getYectScanCreate, { isLoading }] = useLazyGetYexyScanCreateQuery();
  const [getReportShortLink] = useCreateReportShortLinkMutation();
  const [getReportForBusiness] = useLazyGetReportForBusinessQuery();
  const [errorCount, setErrorCount] = useState(0);
  const { isWeb } = useScreenSize();


  const handleRescan = () => {
    // Set an interval to make the request every 5000 milliseconds (5 seconds)
    setLoading(true);
    setLoadingMore(true);
    const JOB_ID = decryptData?.jobId;
    const intervalId = setInterval(
    () =>
      getYectScanCreate(JOB_ID).then((res) => {
        if (res?.data?.body?.response.length) {
          setData(res?.data?.body?.response);
        } else if (!res?.data?.body?.response.length) {
          setLoadingMore(false);
        }
        setLoading(false);
      }),
    1000
    );
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setLoadingMore(false);
    }, 15000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
      setLoadingMore(false);
    };
  }

  useEffect(() => {
    if (decryptData?.jobId) {
      // Set an interval to make the request every 5000 milliseconds (5 seconds)
      setLoading(true);
      setLoadingMore(true);
      const intervalId = setInterval(
        () =>
          getYectScanCreate(decryptData?.jobId).then((res) => {
            if (res?.data?.body?.response.length) {
              console.log(res?.data?.body?.response, 'res?.data?.body?.response');
              setData(res?.data?.body?.response);
            } else if (!res?.data?.body?.response.length) {
              setLoadingMore(false);
            }

            setLoading(false);
          }),
        1000
      );

      // Set a timeout to clear the interval after 15 seconds
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setLoadingMore(false);
      }, 15000);
      // Clean up the interval and timeout when the component is unmounted
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setLoadingMore(false);
      };
    }

  }, [decryptData?.jobId]); // Include decryptData?.jobId in the dependency array

  useEffect(() => {
    if (data?.length) {
      let count = 0;
      let hasProgress = false;
      data.forEach((item, index) => {
        if (item.status !== STATUSES.SCAN_IN_PROGRESS.value) {

          if (item.inner_status === STATUSES.SCAN_NOT_FOUND.value) {
            count += 3;
          } else {
            if (!item.match_name) {
              count += 1;
            }
            if (!item.match_phone) {
              count += 1;
            }
            if (!item.match_address) {
              count += 1;
            }
          }
        } else {
          hasProgress  = true;
        }

        if (index === data.length - 1 && !hasProgress) {
          setLoadingMore(false);
        }
      });
      setErrorCount(count);
    }
  }, [data]);

  useEffect(() => {
    if (reportId) {
      // Set an interval to make the request every 5000 milliseconds (5 seconds)
      setLoading(true);
      setLoadingMore(true);
      const intervalId = setInterval(
        () =>
          getReportForBusiness(reportId).then((res) => {
            if (res?.data?.body?.response?.length) {
              setData(res?.data?.body?.response);
              setReportJobId(res?.data?.body?.jobId);
              const data = {
                address: res?.data?.body?.name,
                phone: res?.data?.body?.phone,
              };
              setBusinessInfo(data);
            } else if (!res?.data?.body?.response?.length) {
              setLoadingMore(false);
            }

            setLoading(false);
          }),
        1000
      );

      // Set a timeout to clear the interval after 15 seconds
      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setLoadingMore(false);
      }, 15000);
      // Clean up the interval and timeout when the component is unmounted
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setLoadingMore(false);
      };
    }
  },[reportId])

  const arrayColumns = [
    {
      field: "site_name",
      headerName: "Platform",
      // flex: 1.5,
      sortable: false,
      filterable: false,
      editable: false,
      minWidth: 280,
      renderCell: ({ value, row }) => (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {row.image_url ? (
            <img
              style={{ width: "40px", height: "40px" }}
              src={row.image_url}
              alt={value}
            />
          ) : (
            <Avatar aria-label="recipe">{value?.charAt(0).toUpperCase()}</Avatar>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: 1,
              width: "100%",
              maxWidth: "200px",
            }}
          >
            <ElipsedTypography sx={{ fontWeight: 800, textTransform: "" }}>
              {value || "-"}
            </ElipsedTypography>
            {row.url && (
              <MuiLink target={"_blank"} href={row.url}>
                {"View listing"}
              </MuiLink>
            )}
          </Box>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Business Name",
      sortable: false,
      flex: 1.5,
      editable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return row.status === STATUSES.SCAN_IN_PROGRESS.value ? (
          <Box sx={{ width: "80%" }}>
            <LinearProgress color="inherit" />
          </Box>
        ) : (
          <ElipsedTypography
            sx={{
              color: row.match_name || !value ? "#0F1214" : "#E21508",
            }}
          >
            {value || "-"}
          </ElipsedTypography>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      sortable: false,
      minWidth: 210,
      flex: 2,
      editable: false,
      renderCell: ({ value, row }) => {
        if (row.status === STATUSES.SCAN_IN_PROGRESS.value) {
          return (
            <Box sx={{ width: "80%" }}>
              <LinearProgress color="inherit" />
            </Box>
          );
        }
        return row.inner_status === "SCAN_TEMPORARY_PROBLEM" ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="The publisher system is not responding to our scanning requests. This is a temporary problem, and will be fixed shortly.">
                <IconButton
                  disableRipple={true}
                  disableFocusRipple={true}
                  sx={{ pl: 0 }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Box sx={{ overflow: "hidden" }}>
                <ElipsedTypography>Temporary problem</ElipsedTypography>
              </Box>
            </Box>
          </>
        ) : (
          <ElipsedTypographyAddress
            sx={{
              textWrap: "wrap",
              color: row.match_address || !value ? "#0F1214" : "#E21508",
            }}
          >
            {value ? (
              `${value}, ${row?.city || ''}, ${row?.state || ''}, ${row?.zip || ''}`
            ) : (
              "-"
            )}
          </ElipsedTypographyAddress>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      // flex: 1,
      minWidth: 160,
      editable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        if (row.status === STATUSES.SCAN_IN_PROGRESS.value) {
          return (
            <Box sx={{ width: "80%" }}>
              <LinearProgress color="inherit" />
            </Box>
          );
        }
        return (
          <ElipsedTypography
            sx={{
              color: row.match_phone || !value ? "#0F1214" : "#E21508",
            }}
          >
            {value || "-"}
          </ElipsedTypography>
        );
      },
    },
    {
      field: "review_rating",
      headerName: "Rating",
      sortable: false,
      // flex: 1,
      minWidth: 140,
      editable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        if (
          row.hasOwnProperty("review_rating") &&
          value &&
          value > 0 &&
          value <= 5
        ) {
          return (
            <Box>
              <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
                <Rating
                  name="half-rating-read"
                  size={"small"}
                  precision={0.5}
                  value={value}
                  readOnly
                />
                <ElipsedTypography>{value.toFixed(1)}</ElipsedTypography>
              </Box>
              {row?.review_count && row?.review_count > 0 ? (
                <ElipsedTypography>{`${row?.review_count} Review(s)`}</ElipsedTypography>
              ) : (
                <></>
              )}
            </Box>
          );
        } else {
          return <ElipsedTypography>{"-"}</ElipsedTypography>;
        }
      },
    },
    {
      field: "inner_status",
      headerName: "Status",
      sortable: false,
      // flex: 1,
      minWidth: 140,
      editable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return STATUSES[value]?.name ? (
          <Alert
            sx={{
              padding: "3px 10px",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "18px",
              letterSpacing: "0.16px",
              "& .MuiAlert-icon": {
                marginRight: "6px",
              },
            }}
            severity={STATUSES[value]?.name}
          >
            {STATUSES[value]?.label}
          </Alert>
        ) : null;
      },
    },
  ];
  const handlePrint = () => window?.print();

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleClose = () => setOpen(false);

  const handleClick = () => {
    setLoadingUrl(true);
    getReportShortLink({ jobId: decryptData?.jobId || reportJobId }).then((response) => {
      if (!response?.error) {
        const link = response?.data?.body?.shortLink;
        setShortLink(link);
        setOpen(true);
      }
      setLoadingUrl(false);
    })
  }
  return (
    <Box>
      <ScanDialog open={open} handleClose={handleClose} url={shortLink} />
      <ResultPageHeader
        handleRescan={handleRescan}
        loadingMore={loadingMore}
        loading={loading}
        reportId={reportId}
      />
      <ResultPageInfoHeader />
      <Box sx={{  maxWidth: '1345px', margin: '32px auto', padding: '0 24px' }}>
        <BusinessName>{decryptData?.name || ""}</BusinessName>
        <ListingWrapper>
          <ResultPageTypography className="title">
            Powerlistings Scan Results <span>{`(as of ${formatDate(new Date())})`}</span>
          </ResultPageTypography>
          <Box>
            <IconButton disabled={loading || loadingUrl || loadingMore} onClick={handlePrint}>
              <LocalPrintshopOutlinedIcon />
            </IconButton>
            {!reportId && (
              <ShareButton
                disabled={loading || loadingUrl || loadingMore}
                variant={'outlined'}
                startIcon={
                  <>
                    {loadingUrl ? (
                      <></>
                    ) : (
                      <img style={{
                        opacity: loading || loadingUrl || loadingMore ? 0.3 : 1
                      }} src={ShareIcon} alt={'share'} />
                    )}
                  </>
                }
                onClick={handleClick}
                loading={loadingUrl}
              >
                {loadingUrl ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>Share This Report</>
                )}
              </ShareButton>
            )}
          </Box>
        </ListingWrapper>
        <BusinessMainInfoWrapper conatiner spacing={2}>
          {loadingMore || loading ? (
            <Box sx={{ textAlign: "center", width: '100%' }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <ContactInfoWrapper item sm={12} xs={12} md={12} lg={3}>
                <ContactInfoTitle>Contact Information</ContactInfoTitle>
                <ContactInfoInnerWrapper>
                  <ContactInfoBox>
                    <Typography>{businessInfo?.address || decryptData?.address || ""}</Typography>
                    <Typography>{businessInfo?.phone || decryptData?.phone || ""}</Typography>
                  </ContactInfoBox>
                </ContactInfoInnerWrapper>
              </ContactInfoWrapper>
              {errorCount >= 50 && (
                <DataErrorInfoWrapper item sm={12} xs={12} md={12} lg={4}>
                  <ContactInfoTitle>Data Errors</ContactInfoTitle>
                  <DataErrorInfoInnerWrapper>
                    {loading ? (
                      <Box sx={{ margin: '20px auto', textAlign: 'center' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <ContactInfoBox sx={{ display: 'flex', gap: '16px' }}>
                        <ErrorCount>{errorCount}</ErrorCount>
                        <ErrorCountTextBox>
                          <LocationDataTitle>Location Data</LocationDataTitle>
                          <LocationDataTitle>Errors Detected</LocationDataTitle>
                        </ErrorCountTextBox>
                      </ContactInfoBox>
                    )}
                  </DataErrorInfoInnerWrapper>
                </DataErrorInfoWrapper>
              )}
              <PerformanceWrapper sm={12} xs={12} md={12} lg={5}>
                <ContactInfoTitle>Comparison Score</ContactInfoTitle>
                <Performance loadingMore={loadingMore} loading={loading} errorCount={errorCount}/>
              </PerformanceWrapper>
            </>
          )}
        </BusinessMainInfoWrapper>
        {loadingMore ? (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress
              sx={{
                backgroundColor: '#D8D8D8',
                color: '#A8A8A8',
                '.MuiLinearProgress-bar': {
                  backgroundColor: '#A8A8A8',
                }
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 2 }}></Box>
        )}
        <>
          {!loading ? (
            isWeb ? (
              <ListTableWeb
                printRef={printRef}
                isLoading={isLoading}
                data={data}
                arrayColumns={arrayColumns}
              />
            ) : (
              <ListTableMobile
                printRef={printRef}
                isLoading={isLoading}
                columns={arrayColumns}
                rows={data}
              />
            )
          ) : (
            <Box
              sx={{
                mt: 2,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
                minHeight: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      </Box>
      {!loading && (
        <Footer />
      )}
    </Box>
  );
};

export default Result;