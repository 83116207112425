import { styled, Box, Typography } from "@mui/material";

export const FormWrapperStyledBox = styled(Box)(({ theme }) => ({
  width: "442px",
  marginTop: '80px',
  backgroundColor: "white",
  position: "relative",
  zIndex: 1,
  borderRadius: "12px",
  [theme.breakpoints.down(460)]: {
    // marginTop: '80px',
    position: "static",
  },
}));

export const StyledHomeBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  zIndex: 1,
  width: "538px",
  justifyContent: "center",
  alignContent: "center",
  [theme.breakpoints.down(460)]: {
    position: "static",
  },
}));

export const StyledHomePagetitle = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: "48px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px" /* 66.667% */,
  '& > img': {
    width: '254px',
    height: '62px'
  }
}));

export const StyledHomePageDescription = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "28px" /* 66.667% */,
  letterSpacing: "0.2px",
  marginTop: theme.spacing(4),
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "45px",
  fontWeight: 500,
  textAlign: "center",
  color: '#1C1B1C',
  lineHeight: '48px',
  [theme.breakpoints.down('sm')]: {
    fontSize: "24px",
    lineHeight: 'inherit',
  }
}));

export const StyledFormHeader = styled(Box)(({ theme }) => ({
  paddingTop: "24px",
  paddingBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "12px",
}));

export const StyledFormFooter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(1),
  paddingBottom: "14px",
  color: 'rgba(122, 122, 122, 1)',
  fontSize: '12px',
}));

export const InputTitles = styled(Typography)(({ theme }) => ({
 fontSize: '16px',
 lineHeight: '16.68px',
 color: '#1C1B1C',
 paddingBottom: theme.spacing(0.5)
}));
