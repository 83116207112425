import {
  StyledHomeBoxWrapper,
  StyledHomePageDescription,
  StyledHomePagetitle,
} from "../styles";
import LightLogo from 'assets/images/lightLogo.png';
function HomePageInfo() {
  return (
    <StyledHomeBoxWrapper>
      <StyledHomePagetitle>
        <img src={LightLogo} alt="light-logo"/>
      </StyledHomePagetitle>
      <StyledHomePageDescription>
        Check The Health Of Your Online Business Information With Help From Our Listings Scan.
        Instantly See How Your Most Critical Location Data Appears On Google, Yelp, Facebook
        And Dozens More Maps, Apps, Search Engines, And Directories
      </StyledHomePageDescription>
    </StyledHomeBoxWrapper>
  );
}

export default HomePageInfo;
