import { configureStore } from "@reduxjs/toolkit";
import { api } from "api";
import { rtkQueryErrorLogger } from "features/errorLogger";
import errorReducer from "features/error";

const middlewares = [api.middleware, rtkQueryErrorLogger];

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    error: errorReducer.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export default store;
