import React from "react";
import YexyCreateForm from "./components/YexyCreateForm";
import { Grid, Box } from "@mui/material";
import HomePageInfo from "./components/HomePageInfo";
import useScreenSize from "hooks/useScreenSize";
import ErrorBoundary from "components/ErrorBoundary";
import Header from "components/Header/Header";

const YexyCreate = () => {
  const { isWeb, isMobile } = useScreenSize();
  return (
    <ErrorBoundary>
        {!isMobile && <Box className="homeBackgroundWrapper"></Box>}
      <Header />
      <Grid
        container
        columnSpacing={0}
        sx={{ height: isMobile ? '100%' : "100vh", padding: isMobile ? '16px' : "36px" }}
      >
        {isWeb && (
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HomePageInfo />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: isWeb ? "flex-start" : "center",
          }}
        >
          <YexyCreateForm />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default YexyCreate;
