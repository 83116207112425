import React from "react";
import {
  RescanButton,
  StyledGridContainer,
  StyledHeaderBox,
  StyledHeaderDescription,
  StyledHeaderLogo
} from "pages/Result/styles";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import logoLight from "assets/images/logoLight.png";
import useScreenSize from "../../hooks/useScreenSize";



const ResultPageHeader = ({ handleRescan, loadingMore, loading, reportId }) => {
  const { isWeb } = useScreenSize();
  return (
    <StyledHeaderBox id={'header'}>
      <StyledGridContainer sx={{ width: isWeb && reportId ? '56%' : '100%' }} id={'header-container'}>
        <Box>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <StyledHeaderLogo sx={{ py: 1.5 }}>
              <img src={logoLight} alt="Orders.co" />
            </StyledHeaderLogo>
          </Link>
        </Box>
        <Box>
          <StyledHeaderDescription>Local Business Scan Tool</StyledHeaderDescription>
        </Box>
        {!reportId && (
          <Box>
            <RescanButton
              variant={'outlined'}
              disabled={loadingMore || loading}
              onClick={handleRescan}
            >Rescan
            </RescanButton>
          </Box>
        )}
      </StyledGridContainer>
    </StyledHeaderBox>
  )
}

export default ResultPageHeader;