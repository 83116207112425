import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { get } from 'lodash';

const CustomMobilePhone = ({
   name,
   id,
   sx = {
     width: '100%',
   },
   children,
   label = '',
   inputProps,
   hidden,
   onChange,
   disabled,
   InputProps,
   size = 'small',
   labelProps = {},
   ...params
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  // const handleBlur = (e) => {
  //   const { value } = e.target;
  //   if (!parsedError?.message && value?.trim()?.length) {
  //     phoneValidator({
  //       phoneNumber: value,
  //     });
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <Controller
      name={name}
      control={control}
      {...params}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            size={size}
            hidden={hidden}
            error={!!parsedError || params.error || false}
            helperText={parsedError ? parsedError.message : params.helperText || ''}
            label={label}
            inputRef={field.ref}
            disabled={disabled}
            InputProps={InputProps}
            sx={{
              ...sx,
            }}
            inputProps={{
              ...inputProps,
            }}
            onChange={onChange || field.onChange}
            onBlur={field.onBlur}
            InputLabelProps={labelProps}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};

export default CustomMobilePhone;
