import { Box } from '@mui/material';
import {ResultSecondHeaderButton, ResultSecondHeaderTitle, ResultSecondHeaderWrapper} from "pages/Result/styles";


const ResultPageInfoHeader = () => {
  const handleOpen = () => window.open('https://orders.co/contact-us/', '_blank');
  return (
    <ResultSecondHeaderWrapper>
      <Box sx={{  maxWidth: '1345px', margin: '0 auto' }}>
        <ResultSecondHeaderTitle>
          Discover How Orders.co Can Enhance Your Online Presence Through Optimized Listings.
        </ResultSecondHeaderTitle>
        <ResultSecondHeaderButton variant={'contained'} onClick={handleOpen}>Schedule A FREE Demo</ResultSecondHeaderButton>
      </Box>
    </ResultSecondHeaderWrapper>
  )
}

export default ResultPageInfoHeader