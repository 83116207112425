import React from "react";
import { get } from "lodash";
import { Box, TextField, Typography } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import { formatNumber } from "helpers/formPrice";

const onWheelPreventChangeNumberField = (e) => {
  e.target.blur();
  e.stopPropagation();
};

const CustomTextInput = ({
  name,
  id,
  sx,
  children,
  label = "",
  validate,
  inputProps,
  hidden,
  onChange,
  disabled,
  multiline,
  rows,
  select,
  helperText,
  InputProps,
  labelProps = {},
  onKeyPress,
  onBlur,
  ...params
}) => {
  const { control, formState, register } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  return (
    <Box sx={{ height: "64px" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          if (field?.name?.includes("phone")) {
            return (
              <MuiPhoneNumber
                defaultCountry={"us"}
                variant="outlined"
                {...field}
                size={"small"}
                hidden={hidden}
                error={!!parsedError || params.error || false}
                helperText={
                  typeof helperText !== "object"
                    ? parsedError
                      ? parsedError.message
                      : params.helperText || ""
                    : helperText
                }
                label={label}
                onWheel={onWheelPreventChangeNumberField}
                disabled={disabled}
                InputProps={{
                  ...inputProps,
                }}
                sx={{
                  ...sx,
                  width: "100%",
                  "& svg": { height: "15px" },
                }}
                autoComplete="off"
                inputProps={inputProps}
                InputLabelProps={labelProps}
                ref={register(name)?.ref}
              />
            );
          } else {
            const formattedValue = field.name.includes("price")
              ? formatNumber(field.value)
              : field.value;

            return (
              <TextField
                {...field}
                size="small"
                hidden={hidden}
                error={!!parsedError || params.error || false}
                helperText={
                  typeof helperText !== "object"
                    ? parsedError
                      ? parsedError.message
                      : params.helperText || helperText
                    : helperText
                }
                label={label}
                inputRef={field.ref}
                disabled={disabled}
                InputProps={{
                  ...inputProps,
                  autoComplete: 'new-password',
                }}
                onWheel={onWheelPreventChangeNumberField}
                sx={{
                  ...select,
                  width: "100%",
                }}
                multiline={multiline}
                rows={rows}
                select={select}
                inputProps={inputProps}
                autoComplete="off"
                onChange={onChange || field.onChange}
                onBlur={onBlur || field.onBlur}
                InputLabelProps={labelProps}
                value={formattedValue === null ? "" : formattedValue}
                // {...params}
              >
                {children}
              </TextField>
            );
          }
        }}
        {...params}
      />
    </Box>
  );
};

export default CustomTextInput;
