import {
    Alert,
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Link,
    LinearProgress, Link as MuiLink, Rating,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { STATUSES } from "constants/config";
import { ElipsedTypography } from "../styles";
import React from "react";

const PlatformCol = ({ item, col }) => {
  return (
    <>
      <Box
        sx={{
          py: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        {item.image_url ? (
          <img
            style={{ width: "40px", height: "40px" }}
            src={item.image_url}
            alt={item[col.field]}
          />
        ) : (
          <Avatar aria-label="recipe">
            {item[col.field]?.charAt(0)?.toUpperCase()}
          </Avatar>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 1,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <ElipsedTypography>{item[col.field] || "-"}</ElipsedTypography>
          {item.url && (
            <Link href={item.url}>{"View listing"}</Link>
          )}
        </Box>
      </Box>
      {item.inner_status === STATUSES.SCAN_TEMPORARY_PROBLEM.value && (
        <>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlinedIcon sx={{ mr: 1, width: "22px" }} /> Temporary problem
          </Typography>
          <Typography sx={{ fontSize: "12px", ml: 4, mt: 1 }}>
            The publisher system is not responding to our scanning requests.
            This is a temporary problem, and will be fixed shortly.
          </Typography>
        </>
      )}
    </>
  );
};

const BusinessNameCol = ({ item, col }) => {
  return (
    <ElipsedTypography
      sx={{
        py: 1,
        color: item.match_name || !item[col.field] ? "#0F1214" : "#E21508",
      }}
    >
      {item[col.field] || "-"}
    </ElipsedTypography>
  );
};

const AddressCol = ({ item, col }) => {
  if (col.status === STATUSES.SCAN_IN_PROGRESS.value) {
    return (
      <Box sx={{ width: "80%" }}>
        <LinearProgress color="inherit" />
      </Box>
    );
  }
  return (
    <Typography
      sx={{
        py: 1,
        color: item.match_address || !item.address ? "#0F1214" : "#E21508",
      }}
    >
      {item.address ? (
        `${item.address}, ${item?.city || ''}, ${item?.state || ''}, ${item?.zip || ''}`
      ) : (
        "-"
      )}
    </Typography>
  );
};

const PhoneCol = ({ item, col }) => {
  if (col.status === STATUSES.SCAN_IN_PROGRESS.value) {
    return (
      <Box sx={{ width: "80%" }}>
        <LinearProgress color="inherit" />
      </Box>
    );
  }
  return (
    <ElipsedTypography
      sx={{
        py: 1,
        color: item.match_phone || !item.phone ? "#0F1214" : "#E21508",
      }}
    >
      {item.phone || "-"}
    </ElipsedTypography>
  );
};

const RatingCal = ({ item, col }) => {
  if (col.status === STATUSES.SCAN_IN_PROGRESS.value) {
    return (
      <Box sx={{ width: "80%" }}>
        <LinearProgress color="inherit" />
      </Box>
    );
  }
    if (
      item?.hasOwnProperty("review_rating") &&
      item?.review_rating &&
      item?.review_rating > 0 &&
      item?.review_rating <= 5
    ) {
     return (
       <Box>
         <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
           <Rating
             name="half-rating-read"
             size={"small"}
             precision={0.5}
             value={item?.review_rating}
             readOnly
         />
           <Typography sx={{ py: 1 }}>
             {item.review_rating.toFixed(1) || "-"}
           </Typography>
         </Box>
         {item?.review_count && item?.review_count > 0 ? (
           <ElipsedTypography>{`${item?.review_count} Review(s)`}</ElipsedTypography>
         ) : (
           <></>
         )}
       </Box>
     )
    } else {
      return <ElipsedTypography>{"-"}</ElipsedTypography>;
    }
};

const StatusCal = ({ item }) => {
  return (
    <Typography sx={{ py: 1, marginLeft: '-5px', }}>
      {STATUSES[item.inner_status]?.name ? (
        <Alert
          sx={{
            width: "max-content",
            padding: '3px 10px',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '18px',
            letterSpacing: '0.16px',
            '& .MuiAlert-icon': {
              marginRight: '6px',
            },
          }}
          severity={STATUSES[item.inner_status]?.name}
        >
          {STATUSES[item.inner_status]?.label}
        </Alert>
      ) : null}
    </Typography>
  );
};

const CardItem = ({ columns, item, unavailable }) => {
  return (
    <Card sx={{ mb: 2, width: "100%" }}>
      <CardContent>
        {!false ? (
          columns.map((col, index) => (
            <Grid key={index} container rowSpacing={2}>
              {!unavailable && (
                <Grid
                  item
                  sm={!unavailable ? 6 : 12}
                  xs={!unavailable ? 6 : 12}
                  md={!unavailable ? 6 : 12}
                >
                  <Typography sx={{ py: 1, fontWeight: "bold" }}>
                    {col.headerName}
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                sm={!unavailable ? 6 : 12}
                xs={!unavailable ? 6 : 12}
                md={!unavailable ? 6 : 12}
              >
                {col.field === "site_name" && (
                  <PlatformCol item={item} col={col} />
                )}
                {col.field === "name" && !unavailable && (
                  <BusinessNameCol item={item} col={col} />
                )}
                {col.field === "address" && !unavailable && (
                  <AddressCol item={item} col={col} />
                )}
                {col.field === "phone" && !unavailable && (
                  <PhoneCol item={item} col={col} />
                )}
                {col.field === "review_rating" && !unavailable && (
                  <RatingCal item={item} col={col} />
                )}
                {col.field === "inner_status" && !unavailable && (
                  <StatusCal item={item} col={col} />
                )}
              </Grid>
            </Grid>
          ))
        ) : (
          <>
            <PlatformCol item={item} col={item} />
            <Tooltip title="The publisher system is not responding to our scanning requests. This is a temporary problem, and will be fixed shortly.">
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
              Temporary problem
            </Tooltip>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export const ListTableMobile = ({ columns, rows }) => {
  return rows.map((item, index) => {
    return (
      <Box sx={{ marginTop: 3 }} key={index}>
        <CardItem
          key={index}
          item={item}
          columns={columns}
          unavailable={
            item.inner_status === STATUSES.SCAN_TEMPORARY_PROBLEM.value
          }
        />
      </Box>
    );
  });
};
