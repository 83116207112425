import {HeaderWrapper, HeaderWrapperBox} from "./styles";
import LightLogo from 'assets/images/lightLogo.png';

const Header = () => {
  return (
   <HeaderWrapper>
     <HeaderWrapperBox>
       <img src={LightLogo} alt="light-logo"/>
     </HeaderWrapperBox>
   </HeaderWrapper>
  )
}
export default Header;