export const secretKey = "res";

// Encryption function
export const encryptText = (text, key = secretKey) => {
  const utf8Text = unescape(encodeURIComponent(text));
  const encrypted = btoa(utf8Text);
  return encrypted;
};

// Decryption function
export const decryptText = (encryptedText, key = secretKey) => {
  const decrypted =  decodeURIComponent(escape(atob(encryptedText)));
  return decrypted;
};
